<template>
  <b-form-group
    label="Users"
    label-for="users"
  >
    <vue-autosuggest
      :suggestions="suggestedUsers"
      :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Search users by Username'}"
      :get-suggestion-value="suggestion => suggestion.item.username"
      @input="onInputChange"
      @selected="selectHandler"
    >
      <template slot-scope="{suggestion}">
        <span class="my-suggestion-item">{{ suggestion.item.username }}</span>
      </template>
    </vue-autosuggest>
  </b-form-group>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  name: 'PortalUsersSearch',
  components: {
    VueAutosuggest,
  },
  data() {
    return {
      suggestedUsers: [],
    }
  },
  methods: {
    onInputChange(query) {
      if (query === '' || query === undefined || query.length < 3) {
        return
      }

      this.$emit('update:username', query)

      this.$portalUsers.get('v1/users/search', {
        params: {
          search: query,
        },
      }).then(res => {
        this.suggestedUsers = [{ data: res.data.data }]
      })
    },
    selectHandler(user) {
      this.$emit('update:username', user.item.username)
    },
  },
}
</script>
<style lang="scss">
@import "src/@core/scss/vue/libs/vue-autosuggest.scss";
</style>
